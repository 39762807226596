var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"Clone Profile","centered":""},on:{"hidden":function($event){return _vm.$emit('modal-closed')},"ok":_vm.onSubmit},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.submitting || _vm.loading},on:{"click":function($event){return ok()}}},[_vm._v(" Clone "),(_vm.submitting)?_c('b-spinner',{attrs:{"small":"","label":"Small Spinner"}}):_vm._e()],1)]}}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(!_vm.loading)?_c('div',[_c('validation-observer',{ref:"profileForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Profile Name","label-for":"profile-name","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"profile-name","value":_vm.profileName,"readonly":""}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Customer Name","label-for":"customer-name","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"customer-name"},on:{"input":function($event){_vm.errorMessage = null}},model:{value:(_vm.customerName),callback:function ($$v) {_vm.customerName=$$v},expression:"customerName"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Project","vid":"project","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Project","label-for":"project","label-cols-md":"4","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"project","options":_vm.projectOptions},on:{"input":_vm.onChangeProject},model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3494967783)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Country Code","vid":"countryCode","mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Country Code","label-for":"country-code","label-cols-md":"4","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"country-code","options":_vm.countryOptions,"label":"label","reduce":function (option) { return option.value; }},on:{"input":function($event){_vm.errorMessage = null}},model:{value:(_vm.countryCode),callback:function ($$v) {_vm.countryCode=$$v},expression:"countryCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1212948054)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Email Domain","label-for":"email-domain","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"email-domain"},on:{"input":function($event){_vm.errorMessage = null}},model:{value:(_vm.emailDomain),callback:function ($$v) {_vm.emailDomain=$$v},expression:"emailDomain"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Email From","label-for":"email-from","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"email-from"},on:{"input":function($event){_vm.errorMessage = null}},model:{value:(_vm.emailFrom),callback:function ($$v) {_vm.emailFrom=$$v},expression:"emailFrom"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Email Subject","label-for":"email-subject","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"email-subject"},on:{"input":function($event){_vm.errorMessage = null}},model:{value:(_vm.emailSubjectMatchText),callback:function ($$v) {_vm.emailSubjectMatchText=$$v},expression:"emailSubjectMatchText"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Mode of Transport","vid":"modeOfTransport","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Mode of Transport","label-for":"mode-of-transport","label-cols-md":"4","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"mode-of-transport","options":_vm.modeOfTransportOptions},on:{"input":function($event){_vm.errorMessage = null}},model:{value:(_vm.modeOfTransport),callback:function ($$v) {_vm.modeOfTransport=$$v},expression:"modeOfTransport"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2258783697)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Select documents to copy (Unselected documents will be created blank)","label-for":"documents","label-class":"font-1rem"}},_vm._l((_vm.documents),function(document,index){return _c('div',{key:index,staticClass:"my-1"},[(_vm.documentTypes.includes(document.doc_type))?_c('b-form-checkbox',{on:{"change":function($event){_vm.errorMessage = null}},model:{value:(_vm.documents[index].checked),callback:function ($$v) {_vm.$set(_vm.documents[index], "checked", $$v)},expression:"documents[index].checked"}},[_vm._v(" "+_vm._s(document.label)+" ")]):_vm._e()],1)}),0)],1)],1),_c('b-alert',{staticClass:"my-1",attrs:{"variant":"danger","show":_vm.errorMessage !== null ? true : false}},[_c('div',{staticClass:"alert-body"},[_c('p',[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])])])],1)],1)],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }