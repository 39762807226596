<template>
  <b-modal
    v-model="showModal"
    title="Definitions Import / Export"
    centered
    @hidden="$emit('modal-closed')"
    @ok="onSubmit"
  >
    <div>
      <validation-observer ref="profileForm">

        <b-alert
          variant="primary"
          show
        >
          <div
            class="alert-body"
          >{{ profile.name }}
          </div>
        </b-alert>
        <b-tabs
          v-model="tabIndex"
          content-class="mt-3"
          justified
          pills
        >
          <b-tab
            v-for="option of tabOptions"
            :key="option"
            :title="option"
            :active="option === tabOptions[tabIndex]"
          >
            <div v-if="tabIndex == 1">
              <div
                v-if="loading"
                class="text-center"
              >
                <b-spinner variant="primary" />
              </div>
              <b-form
                v-else
                @submit.prevent="onSubmit"
              >
                <b-form-group
                  label="Select documents to export (Unselected documents will be created blank)"
                  label-for="documents"
                  label-class="font-1rem"
                >
                  <div
                    v-for="(document, index) in documentsExport"
                    :key="index + 'export'"
                    class="my-1"
                  >
                    <b-form-checkbox
                      v-model="documentsExport[index].checked"
                      @change="errorMessage = null"
                    >
                      {{ document.label }}
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-form>
            </div>
            <div v-if="tabIndex == 0">
              <b-form @submit.prevent="onSubmit">
                <b-form-group
                  label="Profile JSON File"
                >
                  <b-form-file
                    v-model="definitionsFile"
                    accept=".json"
                    :disabled="loadingFile"
                    @input="loadFile"
                  />
                </b-form-group>

                <div
                  v-if="loadingFile"
                  class="text-center"
                >
                  <b-spinner
                    variant="primary"
                    label="Spinner"
                  />
                </div>

                <b-alert
                  variant="danger"
                  :show="fileLoadError !== null ? true : false"
                >
                  <div class="alert-body">
                    <p>
                      {{ fileLoadError }}
                    </p>
                  </div>
                </b-alert>
                <template v-if="profileDefinitions.definition_data">
                  <p class="mb-25">
                    Definition Data
                  </p>

                  <div
                    v-for="(updateSetting,updateSettingIndex) of updateSettings"
                    :key="updateSettingIndex"
                    class="mb-1"
                  >
                    <div>
                      {{ updateSetting.version.toUpperCase() }}
                    </div>
                    <div>
                      <b-form-checkbox v-model="updateSettings[updateSettingIndex].key">
                        Update Key Settings
                      </b-form-checkbox>
                      <b-form-checkbox v-model="updateSettings[updateSettingIndex].table">
                        Update Table Settings
                      </b-form-checkbox>
                    </div>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="import-documents"
                    vid="import-documents"
                    mode="eager"
                  >
                    <b-form-group
                      label="Select documents to export (Unselected documents will be created blank)"
                      label-for="documents"
                      label-class="font-1rem"
                    >
                      <div
                        v-for="(document, index) in profileDefinitions.definition_data"
                        :key="index + 'import'"
                        class="my-1"
                      >
                        <b-form-checkbox
                          v-model="documentsImport[index].checked"
                          @change="errorMessage = null"
                        >
                          {{ documentsImport[index].label }}
                        </b-form-checkbox>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                    <template v-if="profileDefinitions.profile_name !== profile.name">
                      <small class="text-danger">Profile mismatched.</small>
                      <b-form-checkbox
                        v-model="acceptDeffrentProfile"
                        @change="errorMessage = null"
                      >
                        Import with different profile.
                      </b-form-checkbox>
                    </template>
                    <b-alert
                      v-if="errorMessage"
                      variant="danger"
                      :show="errorMessage !== null ? true : false"
                      class="my-1"
                    >
                      <div class="alert-body">
                        <p>
                          {{ errorMessage }}
                        </p>
                      </div>
                    </b-alert>
                  </validation-provider>
                </template>
              </b-form>
            </div>
          </b-tab>
        </b-tabs>

      </validation-observer>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>

      <b-button
        variant="primary"
        type="submit"
        :disabled="submitting || loading || isEligibleSubmitting"
        @click="ok()"
      >
        {{ tabOptions[tabIndex] }}
        <b-spinner
          v-if="submitting"
          small
          label="Small Spinner"
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BButton, BForm, BSpinner, BTabs, BTab, BAlert, BModal, BFormCheckbox, BFormFile,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import exportFromJSON from 'export-from-json'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  components: {
    BFormGroup,
    BButton,
    BForm,
    BSpinner,
    BTabs,
    BTab,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormCheckbox,
    BFormFile,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      submitting: false,
      errorMessage: null,
      options: null,
      showModal: true,
      modeOfTransport: null,
      updateSettings: [],
      project: null,
      documentsExport: null,
      documentsImport: null,
      //
      tabOptions: ['Import', 'Export'],
      tabIndex: 0,
      fileLoadError: null,
      definitionsFile: null,
      loadingFile: false,
      profileDefinitions: {},
      acceptDeffrentProfile: false,
    }
  },
  computed: {
    definitionVersions() {
      return this.$store.getters['applicationSettings/definitionVersions']
    },
    isEligibleSubmitting() {
      if (this.tabIndex === 1 && this.documentsExport && this.documentsExport.some(item => item.checked)) return false
      if (this.profileDefinitions.profile_name === this.profile.name || this.acceptDeffrentProfile) {
        if (this.tabIndex === 0 && this.documentsImport && this.documentsImport.some(item => item.checked)) return false
      }
      return true
    },
  },
  watch: {
    tabIndex(value) {
      if (!value) {
        this.documentsExport = this.getDocumentList(this.profile.documents)
      } else if (value && this.profileDefinitions.definition_data) {
        this.documentsImport = this.getDocumentList(this.profileDefinitions.definition_data)
      }
    },
  },
  async created() {
    await this.$store.dispatch('applicationSettings/fetchApplicationSettings')
    this.fetchProfile()
    this.resetUpdateSettings()
  },
  methods: {
    async fetchProfile() {
      this.loading = true
      axios.get(`/dashboard/profiles/${this.profile.id}/`)
        .then(res => {
          const profile = res.data
          this.profile.documents = profile.documents
          this.documentsExport = this.getDocumentList(this.profile.documents)
          this.loadingError = null
          this.loading = false
        }).catch(error => {
          this.loadingError = error?.response?.data?.detail || 'Error fetching profile'
          this.loading = false
        })
    },
    getDocumentList(documents) {
      documents.sort((a, b) => a.id - b.id)

      const documentOptionsDict = {}

      documents.forEach(e => {
        if (documentOptionsDict[e.doc_type]) {
          documentOptionsDict[e.doc_type].push(e)
        } else {
          documentOptionsDict[e.doc_type] = [e]
        }
      })

      const profileDocuments = []

      Object.keys(documentOptionsDict).forEach(key => {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < documentOptionsDict[key].length; i++) {
          let item = {
            label: documentOptionsDict[key][i].doc_type,
            value: `${documentOptionsDict[key][i].doc_type}_${documentOptionsDict[key][i].name_matching_text}`,
            doc_type: documentOptionsDict[key][i].doc_type,
            checked: false,
          }

          if (documentOptionsDict[key].length !== 1) {
            item = {
              ...item,
              label: `${documentOptionsDict[key][i].doc_type} ${i + 1}`,
            }
          }

          profileDocuments.push(item)
        }
      })

      return profileDocuments
    },
    onSubmit(event) {
      event.preventDefault()
      this.errorMessage = null
      this.submitting = true
      if (this.tabIndex < 1) {
        this.importDefinition()
      } else {
        this.exportDefinition()
      }
    },
    exportDefinition() {
      const docTypeNameList = this.documentsExport
        .filter(item => item.checked)
        .map(item => item.value)

      const fileName = `profile-definition-${this.profile.name}`
      axios.post('/pipeline/export_definitions/', {
        profile_name: this.profile.name,
        doc_types: docTypeNameList,
      })
        .then(res => {
          exportFromJSON({
            data: res.data, fileName, exportType: 'json',
          })
          this.showModal = null
          this.submitting = true
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.response?.data?.detail || 'Error importing definitions',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.submitting = false
        })
    },
    importDefinition() {
      const docTypeNameList = this.documentsImport
        .filter(item => item.checked)
        .map(item => item.value)

      axios.post('/pipeline/import_definitions/', {
        profile_name: this.profile.name,
        doc_types_to_update: docTypeNameList,
        update_settings: this.updateSettings,
        definition_data: this.profileDefinitions.definition_data,
      })
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.detail,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.submitting = false
          this.$emit('imported')
          this.showModal = null
        })
        .catch(error => {
          this.errorMessage = error?.response?.data?.detail || 'Something went wrong'
          this.submitting = false
        })
    },
    resetUpdateSettings() {
      this.updateSettings = this.definitionVersions.map(definitionVersion => ({
        version: definitionVersion,
        key: false,
        table: false,
      }))
    },
    async loadFile() {
      this.fileLoaded = false

      if (!this.definitionsFile) {
        this.profileDefinitions = {}
        this.fileLoadError = null
        return
      }

      this.loadingFile = true

      this.parseDefinitions()
        .then(definitions => {
          this.profileDefinitions = definitions
          this.fileLoadError = null
          this.fileLoaded = true
          this.loadingFile = false
          this.acceptDeffrentProfile = false

          this.documentsImport = this.getDocumentList(this.profileDefinitions.definition_data)
        })
        .catch(error => {
          this.profileDefinitions = []
          this.fileLoadError = error.message
          this.loadingFile = false
        })
    },
    parseDefinitions() {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        if (!this.definitionsFile) {
          return reject(new Error('No file provided'))
        }
        const reader = new FileReader()
        // eslint-disable-next-line consistent-return
        reader.onload = () => resolve(JSON.parse(reader.result))
        reader.onerror = () => reject(reader.error)
        reader.readAsText(this.definitionsFile)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
