<template>
  <b-modal
    v-model="showModal"
    title="Clone Profile"
    centered
    @hidden="$emit('modal-closed')"
    @ok="onSubmit"
  >
    <div v-if="!loading">
      <validation-observer
        ref="profileForm"
      >
        <b-form @submit.prevent="onSubmit">
          <b-row>
            <b-col
              md="12"
            >
              <b-form-group
                label="Profile Name"
                label-for="profile-name"
                label-cols-md="4"
              >
                <b-form-input
                  id="profile-name"
                  :value="profileName"
                  readonly
                />
              </b-form-group>
            </b-col>

            <b-col
              md="12"
            >
              <b-form-group
                label="Customer Name"
                label-for="customer-name"
                label-cols-md="4"
              >
                <b-form-input
                  id="customer-name"
                  v-model="customerName"
                  @input="errorMessage = null"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Project"
                vid="project"
                mode="eager"
              >
                <b-form-group
                  label="Project"
                  label-for="project"
                  label-cols-md="4"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="project"
                    v-model="project"
                    :options="projectOptions"
                    @input="onChangeProject"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="12"
            >
              <validation-provider
                #default="{ errors }"
                name="Country Code"
                vid="countryCode"
                mode="eager"
                rules="required"
              >
                <b-form-group
                  label="Country Code"
                  label-for="country-code"
                  label-cols-md="4"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="country-code"
                    v-model="countryCode"
                    :options="countryOptions"
                    label="label"
                    :reduce="option => option.value"
                    @input="errorMessage = null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="12"
            >
              <b-form-group
                label="Email Domain"
                label-for="email-domain"
                label-cols-md="4"
              >
                <b-form-input
                  id="email-domain"
                  v-model="emailDomain"
                  @input="errorMessage = null"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="12"
            >
              <b-form-group
                label="Email From"
                label-for="email-from"
                label-cols-md="4"
              >
                <b-form-input
                  id="email-from"
                  v-model="emailFrom"
                  @input="errorMessage = null"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="12"
            >
              <b-form-group
                label="Email Subject"
                label-for="email-subject"
                label-cols-md="4"
              >
                <b-form-input
                  id="email-subject"
                  v-model="emailSubjectMatchText"
                  @input="errorMessage = null"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="12"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Mode of Transport"
                vid="modeOfTransport"
                mode="eager"
              >
                <b-form-group
                  label="Mode of Transport"
                  label-for="mode-of-transport"
                  label-cols-md="4"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="mode-of-transport"
                    v-model="modeOfTransport"
                    :options="modeOfTransportOptions"
                    @input="errorMessage = null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group
                label="Select documents to copy (Unselected documents will be created blank)"
                label-for="documents"
                label-class="font-1rem"
              >
                <div
                  v-for="(document, index) in documents"
                  :key="index"
                  class="my-1"
                >
                  <b-form-checkbox
                    v-if="documentTypes.includes(document.doc_type)"
                    v-model="documents[index].checked"
                    @change="errorMessage = null"
                  >
                    {{ document.label }}
                  </b-form-checkbox>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-alert
            variant="danger"
            :show="errorMessage !== null ? true : false"
            class="my-1"
          >
            <div class="alert-body">
              <p>
                {{ errorMessage }}
              </p>
            </div>
          </b-alert>
        </b-form>
      </validation-observer>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>

      <b-button
        variant="primary"
        type="submit"
        :disabled="submitting || loading"
        @click="ok()"
      >
        Clone
        <b-spinner
          v-if="submitting"
          small
          label="Small Spinner"
        />
      </b-button>
    </template>

    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        variant="primary"
      />
    </div>
  </b-modal>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BButton, BForm, BSpinner, BAlert, BModal, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import axios from 'axios'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  components: {
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BForm,
    BSpinner,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BModal,
    BFormCheckbox,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      submitting: false,
      errorMessage: null,
      options: null,
      showModal: true,
      countryCode: null,
      customerName: null,
      modeOfTransport: null,
      project: null,
      documents: null,
      emailDomain: null,
      emailFrom: null,
      emailSubjectMatchText: null,
      emailSubject: null,
    }
  },
  computed: {
    userProjects() {
      return this.$store.getters['auth/projectCountries'].map(e => e.project)
    },
    countryOptions() {
      const countryCodeOptions = this.options?.country_code || []
      return countryCodeOptions.map(item => ({ label: `${item.name} - ${item.code}`, value: item.code }))
    },
    modeOfTransportOptions() {
      return this.options?.mode_of_transport || []
    },
    projectOptions() {
      return this.options?.project || []
    },
    profileName() {
      if (this.countryCode && this.customerName && this.modeOfTransport && this.project) {
        return `${this.countryCode}_${this.customerName.toUpperCase()}_${this.modeOfTransport}_${this.project}`
      }
      return ''
    },
    documentTypes() {
      if (!this.project) {
        return []
      }

      const docTypeSettings = this.$store.getters['definitionSettings/options']['options-meta-root-type']

      if (!docTypeSettings) {
        return []
      }

      return docTypeSettings.items.map(item => item[docTypeSettings.valueKey])
    },
  },
  created() {
    this.countryCode = this.profile.country
    this.customerName = this.profile.customer_name
    this.modeOfTransport = this.profile.mode_of_transport
    this.project = this.profile.project
    this.initializeForm()
  },
  methods: {
    async initializeForm() {
      this.loading = true
      // Get Field Options
      try {
        const profileFieldsResponse = await axios.get('/dashboard/profile_fields_options/')
        this.options = profileFieldsResponse.data
        const res = await axios.get('/dashboard/projects/', {
          params: {
            no_pagination: true,
            sort_by: 'name',
          },
        })

        const projectOptions = res.data.map(e => e.name)

        this.options.project = projectOptions.filter(e => this.userProjects.includes(e))
        await this.onChangeProject(this.project, false)
      } catch (error) {
        this.errorMessage = error?.response?.data?.detail || 'Error fetching field options'
        this.loading = false
        return
      }

      if (!this.profile?.id) {
        this.loading = false

        return
      }

      try {
        const profileDataResponse = await axios.get(`/dashboard/profiles/${this.profile.id}/`)
        const profileData = profileDataResponse.data
        this.emailDomain = profileData.email_domains
        this.emailFrom = profileData.email_from
        this.emailSubjectMatchText = profileData.email_subject_match_text
        this.documents = this.getDocumentList(profileData.documents)
        this.loading = false
      } catch (error) {
        this.errorMessage = error?.response?.data?.detail || 'Error fetching profile'
        this.loading = false
      }
    },
    getDocumentList(documents) {
      documents.sort((a, b) => a.id - b.id)

      const documentOptionsDict = {}

      documents.forEach(e => {
        if (documentOptionsDict[e.doc_type]) {
          documentOptionsDict[e.doc_type].push(e)
        } else {
          documentOptionsDict[e.doc_type] = [e]
        }
      })

      const profileDocuments = []

      Object.keys(documentOptionsDict).forEach(key => {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < documentOptionsDict[key].length; i++) {
          let item = {
            label: documentOptionsDict[key][i].doc_type,
            value: `${documentOptionsDict[key][i].doc_type}_${documentOptionsDict[key][i].name_matching_text}`,
            doc_type: documentOptionsDict[key][i].doc_type,
            checked: false,
          }

          if (documentOptionsDict[key].length !== 1) {
            item = {
              ...item,
              label: `${documentOptionsDict[key][i].doc_type} ${i + 1}`,
            }
          }

          profileDocuments.push(item)
        }
      })

      return profileDocuments
    },
    onSubmit(event) {
      event.preventDefault()
      this.errorMessage = null
      this.submitting = true

      this.$refs.profileForm.validate().then(async success => {
        if (!success) {
          this.submitting = false

          return
        }

        try {
          await axios.post('/dashboard/clone_profile/', {
            source_profile_id: this.profile.id,
            email_domains: this.emailDomain,
            email_from: this.emailFrom,
            country: this.countryCode,
            email_subject_match_text: this.emailSubjectMatchText,
            customer_name: this.customerName,
            project: this.project,
            mode_of_transport: this.modeOfTransport,
            fill_documents: this.documents.filter(e => e.checked).map(e => e.value),
          })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profile cloned successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          this.submitting = false
          this.errorMessage = null
          this.showModal = false
          this.$emit('cloned')
        } catch (error) {
          this.errorMessage = error?.response?.data?.detail || 'Something went wrong'
          this.submitting = false
        }
      })
    },
    async onChangeProject(project, resetDocType = true) {
      this.errorMessage = null
      try {
        if (resetDocType || !project) {
          this.documents = this.documents.map(document => ({
            ...document,
            docType: null,
          }))
        }

        if (!project) {
          return
        }

        this.$store.commit('definitionSettings/SET_PROJECT', project)
        await this.$store.dispatch('definitionSettings/fetchData')
        this.documents.forEach(item => {
          const currentItem = item
          currentItem.checked = false
        })
      } catch (error) {
        this.loadingError = error?.response?.data?.detail || 'Error fetching definition settings'
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
