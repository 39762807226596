<template>
  <div>
    <h2>Profile Management</h2>
    <profiles />
  </div>
</template>

<script>
import Profiles from '@/components/Profiles/Profiles.vue'

export default {
  components: {
    Profiles,
  },
}
</script>
